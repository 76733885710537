import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MultiToggleItem from './MultiToggleItem';

const MultiToggleSwitch = ({ switchId, options, selectedValue, onSwitchChange, winWidth }) => {
    const [currentKey, setCurrentKey] = useState(selectedValue);
    const [currentLabel, setCurrentLabel] = useState(
        options.find((opt) => opt.value === currentKey)?.label
    );
    const [sliderPosition, setSliderPosition] = useState(0);
    const [itemWidths, setItemWidths] = useState({});
    const [wait, setWait] = useState(1);

    useEffect(() => {
        const switchWrapper = document.getElementById(switchId);
        if (!switchWrapper) return;

        let totalWidth = 0;
        const widths = {};

        options.forEach((option) => {
            const element = document.getElementById(`${switchId}-item-${option.value}`);
            if (element) {
                const width = element.clientWidth + 1;
                widths[option.value] = width;
                if (option.value === currentKey) {
                    setSliderPosition(totalWidth);
                    setCurrentLabel(options.find((opt) => opt.value === currentKey)?.label);
                }
                totalWidth += width;
            }else {
                setWait((prev) => prev + 1);
            }
        });

        setItemWidths(widths);
    }, [currentKey, winWidth, options, wait]);

    const handleOptionClick = (clickedKey) => {
        setCurrentKey(clickedKey);
        onSwitchChange(clickedKey);
    };

    return (
        <div className="MultiToggle__wrapper" role="presentation">
            <div id={switchId} className="MultiToggle__switch">
                {options.map((option, index) => (
                    <MultiToggleItem
                        key={option.value}
                        index={index}
                        id={`${switchId}-item-${option.value}`}
                        label={option.label}
                        isActive={option.value === currentKey}
                        onClick={() => handleOptionClick(option.value)}
                    />
                ))}
                <div className="MultiToggle__slider" style={{
                    width: itemWidths[currentKey] ? `${itemWidths[currentKey]}px` : 'auto',
                    left: `${sliderPosition - 1}px`
                }}>
                    {currentLabel}
                </div>
            </div>

            {/* CSS Styling */}
            <style jsx>
                {`
                    .MultiToggle {
                        &__wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            width: 100%;
                        }

                        &__switch {
                            display: flex;
                            position: relative;
                            border: 1px solid #E0E0E0;
                            background: #FFF;
                            border-radius: 8px;
                            width: 100%;
                            transition: all 0.3s ease-in-out;

                            > div {
                                text-align: center;
                                cursor: pointer;
                                z-index: 2;
                                transition: color 0.3s ease-in-out;
                                color:  #212121;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 18px;
                            }
                        }

                        &__slider {
                            position: absolute;
                            height: calc(100% + 2px);
                            border-radius: 8px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 1.5px solid #FFD44D;
                            background:  #FFF6DC;
                            top: -1px;

                            display: flex;
                            padding: 8px;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            flex: 1 0 0;

                            -webkit-transition: transform 0.3s ease-out;
                            -moz-transition: transform 0.3s ease-out;
                            -o-transition: transform 0.3s ease-out;
                            transition: transform 0.3s ease-out;

                            // @include breakpoint('lg') {
                            @media (min-width: 1024px) {
                                width: 128px;
                                padding: 8px 16px;
                            }
                        }
                    }
                `}
            </style>
        </div>
    );
};

MultiToggleSwitch.propTypes = {
    switchId: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })
    ).isRequired,
    selectedValue: PropTypes.string,
    onSwitchChange: PropTypes.func.isRequired,
    winWidth: PropTypes.number.isRequired,
};

MultiToggleSwitch.defaultProps = {
    selectedValue: '',
};

export default MultiToggleSwitch;
