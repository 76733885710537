import { Credits as CreditsIcon } from '123rf-ui-core/assets';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

const AICreditPacksItem = (props) => {
    const { 
        item,
    } = props;

    const hasDescriptionItems = item.descriptionItems.length > 0;

    return (
        <div className='AICreditPacksItem__container'>
            <div 
                className='AICreditPacksItem__header'
            >
                <div className='AICreditPacksItem__headerSubWrapper'>
                    <div className='AICreditPacksItem__title'>
                        {item.icon && <img
                            src={item.icon}
                            className="AICreditPacksItem__titleIcon"
                            alt={item.credits}
                            width="24px"
                            height="24px"
                            loading="lazy"
                        />}
                        {item.iconText}
                    </div>
                    <div className='AICreditPacksItem__creditsValue'>
                        <CreditsIcon color='white' fontSize='24' />
                        <div>
                            <div className='AICreditPacksItem__creditsValueLabel'>
                                {item.credits} 
                            </div>
                            <div 
                                className='AICreditPacksItem__creditsValue--subLabel'
                            >
                                {intl.get('PER_DOWNLOAD')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                
            <div className='AICreditPacksItem__descriptionWrapper'>
                <div className='AICreditPacksItem__description'>
                    {item.description}
                </div>
                
                {hasDescriptionItems && (<div className="AICreditPacksItem__descriptionList">
                    {item.descriptionItems.map((descItem, index) => (
                        <div key={descItem.id} className="AICreditPacksItem__row">
                            <div className="AICreditPacksItem__item">
                                <img
                                    src={descItem.icon}
                                    className="AICreditPacksItem__descriptionIcon"
                                    alt={descItem.id}
                                    width="18px"
                                    height="18px"
                                    loading="lazy"
                                />
                                <span>{descItem.title}</span>
                            </div>
                            {item.descriptionItems[index + 1] && ( // Ensure there's a next item
                                <div className="AICreditPacksItem__item">
                                    <img
                                        src={item.descriptionItems[index + 1].icon}
                                        className="CreditPacksItem__icon"
                                        alt={item.descriptionItems[index + 1].id}
                                        width="18px"
                                        height="18px"
                                        loading="lazy"
                                    />
                                    <span>{item.descriptionItems[index + 1].title}</span>
                                </div>
                            )}
                        </div>
                    )).filter((_, index) => index % 2 === 0)}
                </div>)}
            </div>



            <style jsx>
                {`
                    .AICreditPacksItem {
                        &__container {
                            box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.24);
                            border-radius: 6px 6px 4.8px 4.8px;
                            width: 100%;
                        }

                        &__header {
                            display: flex;
                            height: auto;
                            min-height: 60px;
                            padding: 8px;
                            justify-content: center;
                            align-items: center;
                            align-self: stretch;
                            border-radius: 6px 6px 0px 0px;

                            background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${item.thumbnailPng});
                            background-color: lightgray;
                            background-position: 50%;
                            background-size: cover;
                            background-repeat: no-repeat;
                        }

                        &__headerSubWrapper {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 4px;
                            flex: 1 0 0;
                            color: var(--Greyscale-White, #FFF);
                            height: auto;
                            width: 100%;

                            font-size: 12px;
                            font-weight: 600;
                            line-height: 18px;

                            // @include breakpoint('md') {
                            @media (min-width: 768px) {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }

                        &__title {
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            flex: 1 0 0;
                            width: auto;

                            color: var(--Greyscale-White, #FFF);
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 18px;

                            // @include breakpoint('md') {
                            @media (min-width: 768px) {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }

                        &__titleIcon {
                            width: 24px;
                            height: 24px;
                            aspect-ratio: 1/1;
                            padding: 2px;
                        }

                        &__creditsValue {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 4px;
                            flex: 1 0 0;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 18px;

                            // @include breakpoint('md') {
                            @media (min-width: 768px) {
                                font-size: 16px;
                                line-height: 24px;
                            }

                            &--subLabel {
                                font-size: 10px;
                                font-weight: 400;
                                line-height: 18px; /* 180% */
                            }
                        }

                        &__creditsValueLabel {
                            width: auto;
                            min-width: 71px;
                            height: 19px;
                        }

                        &__descriptionWrapper {
                            display: flex;
                            padding: var(--16px, 16px) var(--8px, 8px);
                            flex-direction: column;
                            align-items: center;
                            gap: var(--8px, 8px);
                            align-self: stretch;

                            border-radius: 0px 0px 4.8px 4.8px;
                            background: var(--Greyscale-White, #FFF);

                            font-size: 12px;
                            font-weight: 400;
                            line-height: 18px;
                        }

                        &__description {
                            display: flex;
                            padding-bottom: ${hasDescriptionItems ? '8px' : 'unset'};
                            border-bottom: ${hasDescriptionItems ? '1px solid var(--Greyscale-G35, #E0E0E0)' : 'unset'};
                            align-items: flex-start;

                            gap: 8px;
                            align-self: stretch;
                        }

                        &__descriptionIcon {
                            display: flex;
                            width: 18px;
                            height: 18px;
                            padding: 1.5px;
                            justify-content: center;
                            align-items: center;
                        }

                        &__descriptionItem {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            gap: 8px;
                            width: 100%;
                            height: auto;
                        }

                        &__descriptionList {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            width: 100%;
                        }

                        &__row {
                            display: flex;
                            justify-content: space-between;
                            gap: 8px; 
                            height: auto;

                            align-items: center;
                            gap: 8px;
                            align-self: stretch;
                        }

                        &__item {
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            min-height: 18px;
                            flex: 1 0 0;
                        }
                    }
                `}
            </style>
        </div>
    );
};

AICreditPacksItem.propTypes = {
    item: PropTypes.objectOf(PropTypes.shape).isRequired,
};

AICreditPacksItem.defaultProps = {
    // isAllInOnePlanOn: false,
};

export default AICreditPacksItem;
