import React, { memo, useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Pre-defined Components
import { DropdownModal } from '123rf-ui-core/components';
import MultiToggleSwitch from 'components/Shared/MutiToggleSwitch';
import CustomSwitch from 'components/Shared/CustomSwitch';
import CustomTabSwitch from 'components/Shared/CustomTabSwitch';
import CreditPacksItem from './CreditPacksItem';
import getCreditPackItems from './getCreditPackItems';
import AICreditPacksItem from './AICreditsPacksItem';

function CreditPacksCanBuy(props) {
    const {
        locale,
        isDesktopView,
        isTabletView,
        isTabletLGView,
        isMobileView,
        winWidth,
        pageOrigin,
        refCreditPacksCanBuy,
        isAllInOnePlanOn,
    } = props;

    // Local states
    const [standardLicense, setStandardLicense] = useState(true);

    const aioOptions = [
        {
            label: intl.get('PRODUCTS.ComparisonTable__StandardLicense'),
            value: 'standardLicense'
        },
        {
            label: intl.get('PRODUCTS.ExtendedLicense'),
            value: 'extendedLicense'
        },
        {
            label: intl.get('AI_SUITE'),
            value: 'aiSuite'
        },
    ];

    const [selectedValue, setSelectedValue] = useState(aioOptions[0].value); // Default to first option

    const creditPackItems = getCreditPackItems(standardLicense, pageOrigin, isAllInOnePlanOn, selectedValue);

    const toggleLicense = () => setStandardLicense((prev) => !prev);

    const isNotAiSuite = useMemo(
        () => selectedValue !== 'aiSuite', 
    [selectedValue]);

    const CustomSwitchMemo = useMemo(
        () => (
            <CustomSwitch
                isAllInOnePlanOn={isAllInOnePlanOn}
                on={standardLicense}
                switchId="credit-pack"
                onText={intl.get('PRODUCTS.ComparisonTable__StandardLicense')}
                offText={intl.get('PRODUCTS.ExtendedLicense')}
                onSwitchChange={toggleLicense}
                origin="cpack-can-buy"
                isDesktopView={isDesktopView}
                isTabletView={isTabletView}
                winWidth={winWidth}
            />
        ),
        [standardLicense, isDesktopView, isTabletView, winWidth],
    );

    useEffect(() => {
        setStandardLicense(selectedValue === 'standardLicense');
    }, [selectedValue]);    

    const handleSwitchChange = (newValue) => {
        setSelectedValue(newValue);
    };

    const handleDropdownChange = (data) => {
        handleSwitchChange(data.value);
    }

    const MultiToggleSwitchMemo = useMemo(
        () => (
            <>
                <MultiToggleSwitch
                    switchId="plan-switch"
                    options={aioOptions}
                    selectedValue={selectedValue}
                    onSwitchChange={handleSwitchChange}
                    winWidth={winWidth}
                />
            </>
        ),
        [aioOptions, winWidth, selectedValue]
    );

    const [isDropdownModalOpen, setIsDropdownModalOpen] = useState(false);

    const handleDropdownToggle = () => {
        setIsDropdownModalOpen(!isDropdownModalOpen);
        document.body.style.overflow = 'auto';
    };

    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        setShowDropdown(false); // Unmount first
        const timeout = setTimeout(() => {
            setShowDropdown(true); // Remount after delay
        }, 10);

        return () => clearTimeout(timeout);
    }, [isMobileView]);

    const CustomTabSwitchMemo = useMemo(
        () => (
            <CustomTabSwitch
                on={standardLicense}
                onText={intl.get('PRODUCTS.ComparisonTable__StandardLicense')}
                offText={intl.get('PRODUCTS.ExtendedLicense')}
                onSwitchChange={toggleLicense}
            />
        ),
        [standardLicense],
    );

    return (
        <section
            className={cx(
                isAllInOnePlanOn ? 'CreditPacksCanBuy__newMainWrapper' : 'CreditPacksCanBuy__mainWrapper'
            )} 
            ref={refCreditPacksCanBuy}>
            <div className={cx(
                isAllInOnePlanOn ? 'CreditPacksCanBuy__newWrapper' : 'CreditPacksCanBuy__wrapper'
            )}>

                {!isAllInOnePlanOn ? (
                    <>
                        <h2 className="CreditPacksCanBuy__title">{creditPackItems.title}</h2>
                        {isMobileView && (
                            <div className="CreditPacksCanBuy__tabSwitchWrapper">{CustomTabSwitchMemo}</div>
                        )}
                        <div className="CreditPacksCanBuy__subTitleWrapper">
                            {standardLicense ? (
                                <div className="CreditPacksCanBuy__subTitle">
                                    {intl.get('PRODUCTS.StandardDetails')}
                                </div>
                            ) : (
                                <div className="CreditPacksCanBuy__subTitle">
                                    {intl.get('PRODUCTS.ExtendedDetails')}
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div className='CreditPacksCanBuy__headerWrapper'>
                        {((isDesktopView || isTabletView) && !isAllInOnePlanOn) && CustomSwitchMemo}
                        {isAllInOnePlanOn ? (
                            !isMobileView ? (
                                MultiToggleSwitchMemo
                            ) : (
                                showDropdown && (
                                    <DropdownModal
                                        className='CreditPacksCanBuy__dropdownModal'
                                        isOpen={isDropdownModalOpen}
                                        onClose={handleDropdownToggle}
                                        options={aioOptions}
                                        selectedValue={selectedValue}
                                        onSelect={handleDropdownChange}
                                        toggleLabel={
                                            aioOptions.find(option => option.value === selectedValue)?.label || ''
                                        }
                                        header={intl.get('LICENSE')}
                                    />
                                )
                            )
                        ) : null}

                        {(isNotAiSuite) && (<div>
                            {standardLicense ? (
                                <div className="CreditPacksCanBuy__subTitle">
                                    {intl.get('PRODUCTS.StandardDetails')}
                                </div>
                            ) : (
                                <div className="CreditPacksCanBuy__subTitle">
                                    {intl.get('PRODUCTS.ExtendedDetails')}
                                </div>
                            )}
                        </div>)}
                    </div>
                )}

                {(isNotAiSuite) ?
                    <div className={cx(
                        !isAllInOnePlanOn ? 'CreditPacksCanBuy__subWrapper' : 'CreditPacksCanBuy__newSubWrapper'
                    )}>
                        {creditPackItems.items.map((item) => (
                            <CreditPacksItem 
                                key={item.id}
                                item={item} 
                                {...props} 
                            />
                        ))}
                    </div>
                    : 
                    <div className='CreditPacksCanBuy__aiSuiteWrapper'>
                        {creditPackItems.items.map((item) => (
                            <AICreditPacksItem
                                key={item.id}
                                item={item}
                                {...props}
                            />
                        ))}
                    </div>
                }

                {(locale?.country?.code !== 'DE' && creditPackItems.footerText)&& (
                    <span className="CreditPacksCanBuy__footerText">
                        {creditPackItems.footerText}
                    </span>
                )}
            </div>

            <style jsx global>
                {`
                    .CreditPacksCanBuy__headerWrapper {
                        > div.DropdownModal__dropdown-toggle {
                            width: unset !important;
                        }

                        .Modalv2__container {
                            z-index: 9999;
                        }

                        .DropdownModal__icon {
                            padding-top: unset;
                            margin-left: unset !important;
                            width: 20px !important;
                            height: 20px !important;
                            flex-shrink: 0;
                        }
                    }
                `}
            </style>

            <style jsx>
                {`
                    .CreditPacksCanBuy {
                        &__mainWrapper {
                            width: 100%;
                            min-height: ${isDesktopView || isTabletLGView
                                ? '542px'
                                : isTabletView
                                ? '427px'
                                : 'auto'};
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            padding: ${isDesktopView || isTabletLGView
                                ? '48px 10px'
                                : isTabletView
                                ? '40px 15px'
                                : '32px 16px'};
                            gap: 10px;
                            background: #f9f9f9;
                        }

                        &__newMainWrapper {
                            position: relative;
                            display: flex;
                            align-items: flex-start;
                            gap: 10px;
                            width: 100%;
                            padding: 16px;
                            background: var(--Greyscale-G45, #F9F9F9);
                            border-radius: ${isMobileView ? '0px 0px 8px 8px' : '0 20px 20px 0'};
                            height: auto;

                            // @include breakpoint('md') {
                            @media (min-width: 768px) {
                                padding: 24px;
                                width: 380px;
                            }

                            // @include breakpoint('lg') {
                            @media (min-width: 1024px) {
                                width: 424px;
                            }
                        }

                        &__wrapper {
                            width: 100%;
                            height: auto;
                            max-width: 964px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding: 0px;
                            gap: ${isDesktopView || isTabletLGView ? '32px' : '24px'};
                        }

                        &__newWrapper {
                            width: 100%;
                            height: auto;
                            min-height: 580px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: ${isNotAiSuite ? '24px' : '16px'};
                        }

                        &__subTitleWrapper {
                            width: ${isDesktopView ? '759px' : isTabletView ? '659px' : '240px'};
                            height: auto;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding: 0px;
                            gap: ${isDesktopView || isTabletLGView ? '32px' : '24px'};
                        }

                        &__title {
                            width: 100%;
                            max-width: ${isDesktopView || isTabletView ? '100%' : '300px'};
                            font-size: ${isDesktopView || isTabletView ? '25px' : '18px'};
                            font-weight: 700;
                            letter-spacing: 0;
                            line-height: ${isDesktopView || isTabletView ? '2.5rem' : '1.5rem'};
                            color: #212121;
                            text-align: center;
                        }

                        &__subTitle {
                            align-self: stretch;
                            text-align: center;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 18px;
                        }

                        &__headerWrapper {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 16px;
                            align-self: stretch;
                        }

                        &__subWrapper {
                            width: ${isTabletView ? '708px' : '100%'};
                            max-width: 1000px;
                            min-height: ${isDesktopView || isTabletLGView
                                ? '320px'
                                : isTabletView
                                ? '252px'
                                : '100%'};
                            display: flex;
                            flex-direction: ${isDesktopView || isTabletView ? 'row' : 'column'};
                            align-items: ${isDesktopView || isTabletView ? 'flex-start' : 'center'};
                            justify-content: center;
                            flex-wrap: wrap;
                            gap: ${isDesktopView ? '32px' : isTabletView ? '15.88px' : '32px'};
                        }

                        &__newSubWrapper {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            gap: 16px;
                            width: 100%;
                        }

                        &__tabSwitchWrapper {
                            width: 100%;
                            max-width: 300px;
                        }

                        &__footerText {
                            max-width: ${isDesktopView || isTabletView ? '100%' : '300px'};
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 1.375rem;
                            text-align: center;
                        }

                        &__aiSuiteWrapper {
                            display: flex;
                            width: 100%;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 16px;
                        }
                    }
                `}
            </style>
        </section>
    );
}

CreditPacksCanBuy.propTypes = {
    locale: PropTypes.objectOf(PropTypes.shape).isRequired,
    isDesktopView: PropTypes.bool.isRequired,
    isTabletView: PropTypes.bool.isRequired,
    isTabletLGView: PropTypes.bool.isRequired,
    isMobileView: PropTypes.bool.isRequired,
    winWidth: PropTypes.number.isRequired,
    pageOrigin: PropTypes.string,
    refCreditPacksCanBuy: PropTypes.objectOf(PropTypes.shape).isRequired,
    isAllInOnePlanOn: PropTypes.bool,
};

CreditPacksCanBuy.defaultProps = {
    pageOrigin: '',
    isAllInOnePlanOn: false,
};

export default memo(CreditPacksCanBuy);
