/* eslint-disable camelcase */
/* eslint-disable no-undef */
import { useContext, useEffect } from 'react';
import dynamic from 'next/dynamic';

// Pre-defined Components
import { ProductDetailsContext } from 'contexts/ProductDetails';

// Sub-Components
import PageHeader from 'components/Shared/PageHeader';
import { AllInOnePlans, NewAffordablePlans } from 'components/Products/NewAffordablePlans';
import TrustedByMillionsWorldwide from 'components/Products/TrustedByMillionsWorldwide';
import CreditPacksCanBuy from 'components/Products/CreditPacksCanBuy';
import PageFooter from 'components/Shared/PageFooter';
import { useGetResponse } from '123rf-ui-core/utils';
import CreditPackSection from 'components/Products/CreditPackSection';

// Loadable Components
const PricingPromoBanner = dynamic(() => import('components/Products/PricingPromoBanner'));
const FrequentlyAskedQuestions = dynamic(
    () => import('components/Products/FrequentlyAskedQuestions'),
    { ssr: false },
);
const UIAgreementBanner = dynamic(() => import('components/Shared/UIAgreementBanner'));
const LiveChatApp = dynamic(() =>
    import('123rf-ui-core/components').then((mod) => mod.LiveChatApp),
);

function ProductsMainPage() {
    const ProductDetails = useContext(ProductDetailsContext);
    const { CommonDetails, GTM, User, WindowDimension, refCreditPacksCanBuy } = ProductDetails;
    const { lang, locale, pageOrigin, isAllInOnePlanOn } = CommonDetails;
    const {
        isMobileView,
        isTabletView,
        isTabletLGView,
        isDesktopView,
        width: winWidth,
    } = WindowDimension;

    // eslint-disable-next-line camelcase
    const { personal_details } = User.userDetails;

    const isGResponseScriptLoaded = useGetResponse();

    const doGrTracking = () => {
        GrTracking('setUserId', personal_details.email);
        GrTracking('setEvent', 'visit_pricing_page', { visit_time: new Date().toISOString() });
    };

    useEffect(() => {
        if (
            isGResponseScriptLoaded &&
            personal_details &&
            personal_details.newsletter.toUpperCase() === 'Y'
        ) {
            doGrTracking();
        }
    }, [isGResponseScriptLoaded]);

    return (
        <>
            <PageHeader
                headerType="simple"
                theme="dark"
                locale={locale}
                User={User}
                lang={lang}
                onSupportClick={() =>
                    GTM.pushGTMData({
                        event: 'SupportLinkPricingClicked',
                        category: 'Contact',
                        label: 'Support',
                        action: 'Click',
                    })
                }
            />
            <main>
                <section className="Products__mainWrapper">
                    <section id="checkout-content">
                        {GTM.initializedGTM && <PricingPromoBanner {...ProductDetails} />}
                        
                        {isAllInOnePlanOn 
                            ? (
                                <>
                                    <AllInOnePlans {...ProductDetails} />
                                    <CreditPackSection {...ProductDetails} />
                                </>
                            ):(
                                <>
                                    <NewAffordablePlans {...ProductDetails} />
                                    <CreditPacksCanBuy
                                        locale={locale}
                                        isDesktopView={isDesktopView}
                                        isTabletView={isTabletView}
                                        isTabletLGView={isTabletLGView}
                                        isMobileView={isMobileView}
                                        winWidth={winWidth}
                                        pageOrigin={pageOrigin}
                                        refCreditPacksCanBuy={refCreditPacksCanBuy}
                                    />
                                </>
                                
                            )
                        }
                        <TrustedByMillionsWorldwide
                            isAllInOnePlanOn={isAllInOnePlanOn}
                            isDesktopView={isDesktopView}
                            isTabletLGView={isTabletLGView}
                            isTabletView={isTabletView}
                            isMobileView={isMobileView}
                        />
                        <FrequentlyAskedQuestions 
                            isAllInOnePlanOn={isAllInOnePlanOn}
                            isTabletLGView={isTabletLGView}
                            isTabletView={isTabletView}
                            isDesktopView={isDesktopView}
                            GTM={GTM} 
                        />
                    </section>
                </section>
            </main>
            <UIAgreementBanner lang={lang} User={User} />
            <PageFooter isAllInOnePlanOn={isAllInOnePlanOn} footerType="simple" locale={locale} />
            <LiveChatApp />
            <style jsx>
                {`
                    .Products {
                        &__mainWrapper {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            min-height: 100vh;
                            width: 100%;
                            background-color: #ffffff;
                        }
                    }
                `}
            </style>
        </>
    );
}

export default ProductsMainPage;
