import PropTypes from 'prop-types';

const MultiToggleItem = ({ id, label, isActive, onClick, index }) => (
  <>
    <div 
      id={id}
      role="button"
      onClick={onClick}
      tabIndex={index}
      onKeyDown={(e) => e.key === 'Enter' && onClick()}
      className={`MultiToggleSwitch__item ${isActive ? 'active' : ''}`}
    >
        {label}
    </div>

    <style jsx>
      {`

        .MultiToggleSwitch {
          &__item {
            display: flex;
            width: 100%;
            height: auto;
            padding: var(--8px, 8px);
            justify-content: center;
            align-items: center;
            gap: 10px;

            color: var(--Greyscale-G25, #A3A3A3);
            text-align: center;
            
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;

            // @include breakpoint('lg') {
            @media (min-width: 1024px) {
              width: 128px;
              padding: var(--8px, 8px) var(--16px, 16px);
            }
          }
        }
      `}
    </style>
  </>
);

MultiToggleItem.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    index: PropTypes.number,
};

MultiToggleItem.defaultProps = {
    isActive: false, // Default to inactive
    index: 0, 
};

export default MultiToggleItem;
