import React, { memo } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { HeightProvider } from 'contexts/PricingPlanHeight';
import { capitalizeFirstLetterOfTheSentence } from 'helpers/shared';

// Pre-defined Components
import CreditPacksCanBuy from 'components/Products/CreditPacksCanBuy';
import { CreditPackV2 } from 'components/Products/NewAffordablePlans/CreditPack';


function CreditPackSection(props) {
    const {
        CommonDetails,
        WindowDimension,
        refCreditPacksCanBuy,
    } = props;

    const { locale, pageOrigin } = CommonDetails;
    const {
        isMobileView,
        isTabletView,
        isTabletLGView,
        isDesktopView,
        width: winWidth,
    } = WindowDimension;

    return (
        <HeightProvider>
            <section className="CreditPackSection__mainWrapper" ref={refCreditPacksCanBuy}>
                <div className="CreditPackSection__title" id="onDemand">
                    {intl.get('PRODUCTS.CreditsSection_title')}
                </div>
                <div className="CreditPackSection__content">
                    {/* CREDITS PRICING CARD */}
                    <div className="CreditPackPlan__wrapper">
                        <div className="CreditPackPlan__header">
                            <div className="CreditPackPlan__title">
                                {intl.get('PRODUCTS.Credits')}
                            </div>
                            <div className="CreditPackPlan__subtitle">
                                {capitalizeFirstLetterOfTheSentence(intl.get('PRODUCTS.AllMediaTypesFlexible'))}.
                            </div>
                        </div>
                        <CreditPackV2 isAllInOnePlanOn {...props} />
                    </div>

                    {/* CREDITS CAN BUY */}
                    <CreditPacksCanBuy
                        locale={locale}
                        isDesktopView={isDesktopView}
                        isTabletView={isTabletView}
                        isTabletLGView={isTabletLGView}
                        isMobileView={isMobileView}
                        winWidth={winWidth}
                        pageOrigin={pageOrigin}
                        refCreditPacksCanBuy={refCreditPacksCanBuy}
                        isAllInOnePlanOn
                    />
                </div>

                <style jsx>
                    {`
                        .CreditPackSection {
                            &__mainWrapper {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                gap: 16px;
                                align-self: stretch;
                                padding: ${isMobileView ? '24px 16px 12px 16px' : '40px 0px'};
                            }

                            &__content {
                                width: 100%;
                                height: auto;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: flex-start;
                                gap: 40px;

                                // @include breakpoint('md') {
                                @media (min-width: 768px) {
                                    display: grid;
                                    grid-template-columns: repeat(2, 1fr);
                                    flex-direction: unset;
                                    gap: unset;
                                    width: 704px;
                                }

                                // @include breakpoint('lg') {
                                @media (min-width: 1024px) {
                                    width: 764px;
                                }
                            }

                            &__title {
                                width: 100%;
                                font-size: ${!isMobileView ? '24px' : '16px'};
                                font-weight: 600;
                                letter-spacing: 0;
                                line-height: ${!isMobileView ? '2.5rem' : '1.5rem'};
                                color: var(--Greyscale-G5, #1A1A1A);
                                text-align: center;
                            }

                            &__subTitle {
                                text-align: center;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }

                        .CreditPackPlan {
                            &__wrapper {
                                width: 100%;
                                position: relative;
                                display: flex;
                                padding: 16px;
                                flex-direction: column;
                                align-items: flex-start;
                                gap: 16px;
                                align-self: stretch;
                                background: var(--Greyscale-G45, #F9F9F9);
                                box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.24), 0px 12px 24px 0px rgba(145, 158, 171, 0.24);
                                border-radius: 8px 8px 0 0;
                                z-index: 1;

                                // @include breakpoint('md') {
                                @media (min-width: 768px) {
                                    width: 324px;
                                    border-radius: 20px 0 0 20px;
                                }

                                // @include breakpoint('lg') {
                                @media (min-width: 1024px) {
                                    width: 340px;
                                    padding: 24px;
                                }
                            }

                            &__header {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                gap: 8px;
                                align-self: stretch;
                            }

                            &__title {
                                color: var(--Greyscale-G10, #212121);
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 24px; /* 150% */

                                // @include breakpoint('md') {
                                @media (min-width: 768px) {
                                    font-size: 24px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 36px; /* 150% */
                                }
                            }

                            &__subtitle {
                                align-self: stretch;
                                color: var(--Greyscale-G10, #212121);

                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;
                            }
                        }
                    `}
                </style>
            </section>
        </HeightProvider>

    );
}

CreditPackSection.propTypes = {
    CommonDetails: PropTypes.objectOf(PropTypes.shape).isRequired,
    GTM: PropTypes.objectOf(PropTypes.shape).isRequired,
    WindowDimension: PropTypes.objectOf(PropTypes.shape).isRequired,
    refCreditPacksCanBuy: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default memo(CreditPackSection);
