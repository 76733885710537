import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Sub Components
import CustomSwitchOn from './CustomSwitchOn';
import CustomSwitchOff from './CustomSwitchOff';

const CustomSwitch = (props) => {
    const { switchId, on, onSwitchChange, origin, winWidth, fullWidth, isAllInOnePlanOn } = props;

    // Local states
    const [isSwitchedOn, setIsSwitchedOn] = useState(on);
    const [onWidth, setOnWidth] = useState('0px');
    const [offWidth, setOffWidth] = useState('0px');
    const [wait, setWait] = useState(1);

    const toggleSwitch = () => {
        setIsSwitchedOn((prev) => !prev);
        onSwitchChange();
    };

    useEffect(() => {
        const switchWrapper = document.getElementById(switchId);
        const onElement = document.getElementById(`${switchId}-on-node`);
        const offElement = document.getElementById(`${switchId}-off-node`);
        if (switchWrapper && onElement && offElement) {
            setOnWidth(`${onElement.clientWidth + 3}px`);
            setOffWidth(`${offElement.clientWidth + 3}px`);
        } else {
            setWait((prev) => prev + 1);
        }
    }, [isSwitchedOn, winWidth, wait]);

    const CustomSwitchSlider = () => {
        if (isSwitchedOn) {
            return <CustomSwitchOn isVisible isSwitchedOn={isSwitchedOn} {...props} />;
        }
        return <CustomSwitchOff isVisible isSwitchedOn={isSwitchedOn} {...props} />;
    };

    return (
        <div
            id="custom-switch-wrapper"
            className="CustomSwitch__mainWrapper"
            role="presentation"
            onClick={toggleSwitch}
            onKeyDown={(e) => e.key === 'Enter' && toggleSwitch(e)}
        >
            <div id={switchId} className="CustomSwitch__wrapper">
                <CustomSwitchOn isVisible={!isSwitchedOn} isSwitchedOn={isSwitchedOn} {...props} />
                <CustomSwitchOff isVisible={isSwitchedOn} isSwitchedOn={isSwitchedOn} {...props} />
            </div>
            <div className="CustomSwitch__slider">
                <CustomSwitchSlider />
            </div>

            <style jsx>
                {`
                    .CustomSwitch {
                        &__mainWrapper {
                            position: relative;
                            width: ${fullWidth ? '100%' : 'fit-content'};
                            height: auto;
                            border-radius: 8px;
                            padding-right: 2px;
                            overflow: hidden;
                            cursor: pointer;
                        }

                        &__wrapper {
                            width: ${fullWidth ? '100%' : 'fit-content'};
                            min-height: ${isAllInOnePlanOn ? '34px' : origin === 'cpack-can-buy' ? '44px' : '40px'};
                            display: flex;
                            align-items: center;
                            background: #ffffff;
                            border: 1.5px solid #E0E0E0;
                            border-radius: 8px;
                        }

                        &__slider {
                            display: ${onWidth === '0px' ? 'none' : 'unset'};
                            width: ${isSwitchedOn ? onWidth : offWidth};
                            height: 100%;
                            background: #fff6dc;
                            border-radius: 8px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            margin-left: ${isSwitchedOn ? '0' : onWidth};
                            z-index: 0;
                            -webkit-transition: margin-left 0.3s ease-out;
                            -moz-transition: margin-left 0.3s ease-out;
                            -o-transition: margin-left 0.3s ease-out;
                            transition: margin-left 0.3s ease-out;
                        }
                    }
                `}
            </style>
        </div>
    );
};

CustomSwitch.propTypes = {
    switchId: PropTypes.string.isRequired,
    on: PropTypes.bool.isRequired,
    onSwitchChange: PropTypes.func,
    origin: PropTypes.string,
    isDesktopView: PropTypes.bool.isRequired,
    isTabletView: PropTypes.bool.isRequired,
    winWidth: PropTypes.number.isRequired,
    fullWidth: PropTypes.bool,
    isAllInOnePlanOn: PropTypes.bool,
};

CustomSwitch.defaultProps = {
    onSwitchChange: () => null,
    origin: 'default',
    fullWidth: false,
    isAllInOnePlanOn: false,
};

export default CustomSwitch;
