import intl from 'react-intl-universal';
import { svg, Globe as GlobeIcon } from "123rf-ui-core/assets";
import { PRODUCTS_IMAGES as images } from 'config/images/products';
import { CREDITS_ONLY_PAGE_ORIGIN as creditsPO } from 'config/products';

const getCreditPackItems = (std, pageOrigin, isAllInOnePlanOn, selectedKey) => {
    if(selectedKey === 'aiSuite') {
        const aiSuiteList = [
            {
                id: 'localise',
                title: intl.get('LOCALISE'),
                icon: GlobeIcon,
            },
            {
                id: 'image-upscaler',
                title: intl.get('IMAGE_UPSCALER'),
                icon: svg.aiImgUpScaler,
            },
            {
                id: 'sketch-to-image',
                title: intl.get('SKETCH_TO_IMAGE'),
                icon: svg.aiSketch,
            },
            {
                id: 'generative-fill',
                title: intl.get('GENERATIVE_FILL'),
                icon: svg.aiGenerativeFill,
            },
            {
                id: 'background-blur',
                title: intl.get('BACKGROUND_BLUR'),
                icon: svg.aiBgBlur,
            },
            {
                id: 'background-remix',
                title: intl.get('BACKGROUND_REMIX'),
                icon: svg.aiBgRemix,
            },
            {
                id: 'image-extender',
                title: intl.get('IMAGE_EXTENDER'),
                icon: svg.aiImgExtender,
            },
            {
                id: 'writer',
                title: intl.get('WRITER'),
                icon: svg.aiWriter,
            },
            {
                id: 'background-remover',
                title: intl.get('BACKGROUND_REMOVER'),
                icon: svg.aiBgRemoval,
            },
        ];
        return {
            items: [
                {
                    id: 'image-generator',
                    thumbnailPng: images.pricingImageGenerator,
                    icon: svg.aiImgGeneratorWhite,
                    iconText: intl.get('IMAGE_GENERATOR'),
                    credits: `${'5'} ${intl.get('SHARED.Credits')}`,
                    description: intl.get('UNLOCK_CREATIVITY'), 
                    descriptionItems: []
                },
                {
                    id: 'use-your-own-images',
                    thumbnailPng: images.pricingUploadYourOwnImages,
                    icon: null,
                    iconText: intl.get('USE_OWN_IMAGE'), 
                    credits: `${'1'} ${intl.get('SHARED.Credit')}`,
                    description: intl.get('UPLOAD_AND_APPLY'),
                    descriptionItems: 
                        aiSuiteList.filter(
                            item => item.id !== 'localise' 
                        ),
                },
                {
                    id: 'stock-content',
                    thumbnailPng: images.pricingStockContent,
                    icon: null,
                    iconText: intl.get('STOCK_CONTENT'), 
                    credits: `${'10'} ${intl.get('SHARED.Credits')}`,
                    description: intl.get('TRANSFORM_AND_DOWNLOAD'),
                    descriptionItems: 
                        aiSuiteList.filter(
                            item => item.id !== 'localise' 
                            && item.id !== 'writer'
                        ),
                },
            ],
        };
    }

    if (!std) {
        return {
            title: intl.get('PRODUCTS.CreditPackYouCanBuy'),
            items: [
                {
                    id: 'image-thumbnail-10',
                    thumbnailWebp: images.imageExtThumbnailWebp,
                    thumbnailPng: images.imageExtThumbnail,
                    icon: images.imageIcon,
                    iconText: intl.get('PRODUCTS.Image'),
                    credits: `${'60'} ${intl.get('SHARED.Credits')}`,
                },
                {
                    id: 'video-thumbnail-30',
                    thumbnailWebp: images.videoExtThumbnailWebp,
                    thumbnailPng: images.videoExtThumbnail,
                    icon: images.videoIcon,
                    iconText: intl.get('PRODUCTS.Video'),
                    credits: `${'80'} ${intl.get('SHARED.Credits')}`,
                },
                {
                    id: 'audio-thumbnail-15',
                    thumbnailWebp: images.audioThumbnailExtWebp,
                    thumbnailPng: images.audioThumbnailExt,
                    icon: images.audioIcon,
                    iconText: intl.get('PRODUCTS.Audio'),
                    credits: `${'60'} ${intl.get('SHARED.Credits')}`,
                },
            ],
            // footerText: intl.get('PRODUCTS.CreditsAreValidForUseAnytime'),
        };
    }

    if(isAllInOnePlanOn) {
        return {
            title: '',
            items: [
                {
                    id: 'photo-thumbnail-10',
                    thumbnailWebp: images.imageThumbnailWebp,
                    thumbnailPng: images.imageThumbnail,
                    icon: images.imageIcon,
                    iconText: intl.get('PRODUCTS.Photos'),
                    credits: `${'10'} ${intl.get('SHARED.Credits')}`,
                },
                {
                    id: 'video-thumbnail-30',
                    thumbnailWebp: images.videoThumbnailWebp,
                    thumbnailPng: images.videoThumbnail,
                    icon: images.videoIcon,
                    iconText: intl.get('PRODUCTS.Videos'),
                    credits: `${'30'} ${intl.get('SHARED.Credits')}`,
                },
                {
                    id: 'vector-thumbnail-10',
                    thumbnailWebp: images.vectorThumbnailWebp,
                    thumbnailPng: images.vectorThumbnail,
                    icon: images.vectorIcon,
                    iconText: intl.get('PRODUCTS.Vectors'),
                    credits: `${'10'} ${intl.get('SHARED.Credits')}`,
                },
                {
                    id: 'audio-thumbnail-15',
                    thumbnailWebp: images.audioThumbnailWebp,
                    thumbnailPng: images.audioThumbnail,
                    icon: images.audioIcon,
                    iconText: intl.get('PRODUCTS.Audio'),
                    credits: `${'10'} ${intl.get('SHARED.Credits')}`,
                },
                {
                    id: 'font-thumbnail-10',
                    thumbnailWebp: images.fontThumbnailWebp,
                    thumbnailPng: images.fontThumbnail,
                    icon: images.fontIcon,
                    iconText: intl.get('PRODUCTS.Font'),
                    credits: `${'10'} ${intl.get('SHARED.Credits')}`,
                },
            ],
        };
    }

    return {
        title: intl.get('PRODUCTS.CreditPackYouCanBuy'),
        items: [
            {
                id: 'image-thumbnail-10',
                thumbnailWebp: images.imageThumbnailWebp,
                thumbnailPng: images.imageThumbnail,
                icon: images.imageIcon,
                iconText: intl.get('PRODUCTS.Image'),
                credits: `${'10'} ${intl.get('SHARED.Credits')}`,
            },
            ...(creditsPO.includes(pageOrigin)
                ? []
                : [
                      {
                          id: 'ai-image-thumbnail-10',
                          thumbnailWebp: images.aiImageThumbnailWebp,
                          thumbnailPng: images.aiImageThumbnail,
                          icon: images.aiImageIcon,
                          iconText: intl.get('PRODUCTS.AiImage'),
                          credits: `${'10'} ${intl.get('SHARED.Credits')}`,
                      },
                  ]),
            {
                id: 'video-thumbnail-30',
                thumbnailWebp: images.videoThumbnailWebp,
                thumbnailPng: images.videoThumbnail,
                icon: images.videoIcon,
                iconText: intl.get('PRODUCTS.Video'),
                credits: `${'30'} ${intl.get('SHARED.Credits')}`,
            },
            {
                id: 'audio-thumbnail-15',
                thumbnailWebp: images.audioThumbnailWebp,
                thumbnailPng: images.audioThumbnail,
                icon: images.audioIcon,
                iconText: intl.get('PRODUCTS.Audio'),
                credits: `${'10'} ${intl.get('SHARED.Credits')}`,
            },
            {
                id: 'font-thumbnail-10',
                thumbnailWebp: images.fontThumbnailWebp,
                thumbnailPng: images.fontThumbnail,
                icon: images.fontIcon,
                iconText: intl.get('PRODUCTS.Font'),
                credits: `${'10'} ${intl.get('SHARED.Credits')}`,
            },
        ],
        // footerText: intl.get('PRODUCTS.CreditsAreValidForUseAnytime'),
    };
};

export default getCreditPackItems;
